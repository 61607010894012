body,
html {
  margin: 0;
  padding: 0;
  outline: none;
  text-decoration: none;
  box-sizing: border-box;
}

body {
  background-color: #f4f4f4;

  & .ant-modal-wrap {
    z-index: 1060 !important;
  }

  & .ant-modal-mask {
    z-index: 1060 !important;
  }
}
